// import React from "react";
import './loader.css';

function Loader(){
    return (
        <>
        <div className="modal1">
            <div className="loader1"></div>
        </div>
    </>
    )
}

export default Loader;