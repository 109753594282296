/* Dev Name: Valentine Bassey. 
   Email: awasevalentine@gmail.com
   Github: https://github.com/awasevalentine
*/


import { useEffect } from "react";
import { useLocation, useNavigate} from "react-router-dom";
import { GiCheckMark } from "react-icons/gi";
import './confirmation-modal.css'

const PaymentConfirmationModal = (prop) => {

    const navigate = useNavigate()
    const location = useLocation()
    var totalAmount = 0;

    const {
        firstName, otherNames, checkInDate, checkOutDate, email, 
        phone, roomType, roomQuantity, amount, gender, 
        personCategory, additionalRequest,roomCategory
    } = location.state

    useEffect((e) => {
        //This method allows us to open the modal when the component is mounted
        var open = document.getElementById("openModel");
        open.click()

    })

    return ( 

        <>

            <button style={{display: 'none'}} type="button" id="openModel" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            </button>

            <div class="modal  modal-dialog-centered fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                <div class='logo'>
                        <GiCheckMark className="log" size={50} color="#991037"  />
                </div>
                    {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    <h5 class="modal-title" id="exampleModalLabel"></h5>
                </div>
                <div class="modal-body">
                    <h4 style={{color: "#991037", textAlign: 'center'}}>Awesome!</h4>
          
                    <p>
                        Hello <b style={{textTransform: 'capitalize'}}>{firstName}</b>, Your booking has been confirmed. Kindly check your email for your reservation details.</p>
                    <div class="reservation-details">

                    </div>
                </div>
                <div class="modal-footer">
                    <a href='https://www.hillsbaygroup.com' class='close_btn'>Close</a>
                </div>
                </div>
            </div>
            </div>
        
        </>
     );
}
 
export default PaymentConfirmationModal;