import 'react-toastify/dist/ReactToastify.css';

const FormValidation = (values) => {


        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.firstName) {
          errors.firstName = "First name is required!";
        }
        if (!values.otherNames) {
          errors.otherNames = "Other name is required!";
        }
        if (!values.phone) {
          errors.phone = "Phone number is required!";
        }
        if (!values.checkInDate) {
          errors.checkInDate = "Check in date is required!";
        }
        if (!values.checkOutDate) {
          errors.checkOutDate = "Check out date is required!";
        }
        if (!values.roomType) {
          errors.roomType = "Room type is required!";
        }
        if (!values.roomCategory) {
          errors.roomCategory = "Room category is required!";
        }
        if (!values.email) {
          errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
          errors.email = "This is not a valid email format!";
        }
        if(values.roomQuantity < 1){
          errors.roomQuantity = "Number of rooms can not be less than 1";
        }

        return errors;
      };
 
export default FormValidation;