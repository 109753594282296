import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;


const getAvailableRooms = async(data) => {
    const availableRooms =  await axios.post(`${baseUrl}/get-available-rooms`, data)

    return availableRooms.data;
}

const createReservation = async (data) => {
    const create =  await axios.post(`${baseUrl}/create-reservation`, data)
    return create.data
}

const getAllBookedRoom = async () =>{
    const getReservations = await axios.get(`${baseUrl}/get-all-reservations`)
}


const ReservationService ={
    getAvailableRooms,
    createReservation,
    getAllBookedRoom
}

export default ReservationService

 