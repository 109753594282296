/* Dev Name: Valentine Bassey. 
   Email: awasevalentine@gmail.com
   Github: https://github.com/awasevalentine
*/

    // Form data initialisation
    export var initialValues = {
        firstName: '',
        otherNames: '',
        checkInDate: '',
        checkOutDate: '',
        email: '',
        phone: '',
        roomType: '',
        roomCategory: '',
        roomQuantity: 1,
        gender: '',
        personCategory: '',
        additionalRequest: '',
        amount: '',
        transactionRef: '',
        transaction: ''
    }

    export const personCategory = [
            'Elderly','Adult', 'Group', 'Couple'
        ]

    export const personTitle = [
                'Mr', 'Mrs', 'Ms', 'Engr',
                'Dr', 'Prof', 'Rev', 'Fr',
                'Barr','Sen', 'Col', 'Gen',
                'Pst'
            ]
    
    export const hotelRoomType = [

        'Smoking', 'Non Smoking'
    ]

    export const hotelRoomCategory = [

         {
            'roomCategory': 'Single Deluxe',
            'Smoking': '32500',
            // 'Non Smoking': '22500',
            // 'totalRoomsBoth': 20

        },
         {
            'roomCategory': 'Standard Deluxe',
            'Smoking': '32500',
            'Non Smoking': '37500',
            // 'totalRoomsBoth': 20
        },
        {
            'roomCategory': 'Luxury',
            'Smoking': '45500',
            'Non Smoking': '40500',
            // 'totalRoomsBoth': 20
        },
        {
            'roomCategory': 'Executive',
            'Smoking': '68500',
            'Non Smoking': '63500',
            // 'totalRoomsBoth': 20
        },
        {
            'roomCategory': 'Hillsbay Classic',
            // 'Smoking': '45000',
            'Non Smoking': '50500',
            // 'totalRoomsBoth': 20
        },
        {
            'roomCategory': 'Honey Suite',
            'Smoking': '85500',
            'Non Smoking': '83500',
            // 'totalRoomsBoth': 20
        },



    ]

export const personGender =[
    'Male', 'Female'
]